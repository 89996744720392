import React from 'react';
import logo from './icon.png';


const DeleteAccountPage = () => {
  return (
    <div style={styles.container}>

        <div align="center">
            <img src={logo} height={120}/>
        </div>
      <h1>Request Account Deletion</h1>
      <p>
        If you would like to request the deletion of your account and associated data for the 
        <strong> Tidyhub Laundry LLC</strong> application, please follow the steps below:
      </p>

      <h2>Steps to Request Account Deletion</h2>
      <ol>
        <li>
          Send an email to <a href="mailto:support@tidyhub.ae">support@tidyhub.ae</a>
        </li>
        <li>
          In the email subject line, include "Account Deletion Request".
        </li>
        <li>
          In the body of the email, provide your registered email address and any other identifying information.
        </li>
      </ol>

      <h2>Data Deletion Information</h2>
      <p>
        Upon your request, the following data will be deleted:
      </p>
      <ul>
        <li>Account information (username, email address, etc.)</li>
        <li>User-generated content (if applicable)</li>
        <li>Any analytics data associated with your account</li>
      </ul>
      <p>
        Please note that some data may be retained for compliance purposes for a period of up to 30 days.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns regarding your data, please feel free to reach out to us at 
        <a href="mailto:support@tidyhub.ae"> support@tidyhub.ae</a>.
      </p>

      <footer style={styles.footer}>
        <p>&copy; {new Date().getFullYear()} Tidyhub Laundry LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '600px',
    margin: 'auto',
    fontFamily: 'Arial, sans-serif',
  },
  footer: {
    marginTop: '20px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px',
    textAlign: 'center',
  },
};

export default DeleteAccountPage;
